import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import html2canvas from "html2canvas";
import {
  getDownloadURL,
  ref,
  uploadString,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import jsPDF from "jspdf";
//Icons
import { BsPrinter } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Srcs
import "../Style/pagestyle.scss";
import { storage } from "../firebase";
import { get_date_filter } from "../Function/DateFilter";
import { AuthContext } from "../Context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import { SelectDepartment } from "../Components/Include/DynamicSelect";
import BalanceSheet from "../Components/Report/BalanceSheet";
import IncomeStatement from "../Components/Report/IncomeStatement";
import GeneralLedger from "../Components/Report/GeneralLedger";

export default function Report() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  let reportTypeFromLocal = JSON.parse(
    window.localStorage.getItem("reportTypeFromLocal")
  );
  const [reportType, setReportType] = useState(
    reportTypeFromLocal ? reportTypeFromLocal : "Income-Statement"
  );
  const [dateSelect, setDateSelect] = useState("this_month");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [monthYear, setMonthYear] = useState(moment());
  const [departmentVal, setDepartmentVal] = useState(
    JSON.parse(window.localStorage.getItem("departmentVal"))
  );
  const [formIncomeStatement, setFormIncomeStatement] = useState("1");

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ============================== print report ===================================
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // =============================== Export to excel ==============================
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${reportType}-Report`,
    sheet: `${reportType}`,
  });

  // ============================== onchange report type ==========================
  const onChnageReport = (e) => {
    setReportType(e);
    window.localStorage.setItem("reportTypeFromLocal", JSON.stringify(e));
  };

  // ============================ Filter date function ==============================
  useEffect(() => {
    async function getDateFiler() {
      if (dateSelect !== "all" && dateSelect !== "") {
        let date = await get_date_filter(dateSelect);
        // console.log("date::", date)
        setStartDate(date?.fromDate);
        setEndDate(date?.toDate);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }
    getDateFiler();
  }, [dateSelect]);

  // ========================= function download card as image ===========================
  const handleSendBotTelegram = async () => {
    // setLoading(true);
    const element = document.getElementById("sendTelegram"),
      canvas = await html2canvas(element, {
        logging: true,
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
      }),
      data = canvas.toDataURL("image/tif", 5),
      link = document.createElement("a");
    link.href = data;
    link.id = "image-002";
    document.body.appendChild(link);
    link.click();
    // document.body.removeChild(link);

    const message2 = data;
    const storageRef = ref(storage, "Images");

    uploadString(storageRef, message2, "data_url").then((snapshot) => {
      // console.log('Uploaded a base64 string!',snapshot);
      getDownloadURL(snapshot.ref).then(async (downloadURL) => {
        console.log("Download link:", downloadURL);

        // let content = `<b>[សេចក្តីជូនដំណឹង]</b>\n\n`;
        // // content += `សូមជម្រាបជូន លោកនាយក និង ប្រធានកិច្ចការរដ្ឋបាល​ និងហិរញ្ញវត្ថុ\n`;
        // content += downloadURL;
        // await sendMessage({ content: content });
        // setTimeout(() => {
        //   setLoading(false);
        // }, 200);
      });
    });
  };

  const downloadPDF = () => {
    const capture = document.getElementById("sendTelegram");
    html2canvas(capture).then((canvas) => {
      const imageData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imageData, "PNG", 0, 0, componentWidth, componentHeight);
      // console.log("doc:::", doc.save('receip.pdf'));
      // doc.save('receip.pdf') fordownload
      const blob = doc.output("blob");
      const url = URL.createObjectURL(blob);
      // urlForUpload = URL.createObjectURL(blob);

      // to firbase
      const newDate = moment(new Date()).format("MMdYYYY");
      let newName = `${uuidv4()}${newDate}`;
      const storageRef = ref(storage, `Images/${newName}`);

      uploadString(storageRef, url, "data_url").then((snapshot) => {
        // console.log('Uploaded a base64 string!',snapshot);
        getDownloadURL(snapshot.ref).then(async (downloadURL) => {
          console.log("Download link:", downloadURL);

          // let content = `<b>[សេចក្តីជូនដំណឹង]</b>\n\n`;
          // // content += `សូមជម្រាបជូន លោកនាយក និង ប្រធានកិច្ចការរដ្ឋបាល​ និងហិរញ្ញវត្ថុ\n`;
          // content += downloadURL;
          // await sendMessage({ content: content });
          // setTimeout(() => {
          //   setLoading(false);
          // }, 200);
        });
      });
    });
  };

  return (
    <div className="page-container" style={{ paddingBottom: "20px" }}>
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">{t("page_report")}</Typography>
          <Typography className="page-subtitle">View all report</Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={0.6}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/">
              {t("page_dashboard")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="breadcrumbs-title">
              {t("page_report")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* <Button onClick={downloadPDF}>Download</Button> */}

      <Box sx={{ marginTop: "30px" }}>
        <Grid item container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Typography className="search-head-title">
              Select report type
            </Typography>
            <FormControl fullWidth size="small" className="search-field">
              <Select
                value={reportType}
                onChange={(e) => onChnageReport(e.target.value)}
              >
                <MenuItem value="Income-Statement">Income statement</MenuItem>
                <MenuItem value="BalanceSheet">Balance sheet</MenuItem>
                <MenuItem value="General-Ledger">General ledger</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={8} lg={9} xl={9}>
            <Stack direction="row" spacing={2} justifyContent="right">
              {/* <Button
                className="btn-create"
                startIcon={<BookIcon alt="CreateIcon" className="icon-add" />}
                onClick={handleOpen}
              >
                Close report
              </Button> */}
              {/* {loading ? (
                <Button
                  className="btn-create"
                  startIcon={
                    <FaTelegramPlane alt="CreateIcon" className="icon-add" />
                  }
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className="btn-create"
                  startIcon={
                    <FaTelegramPlane alt="CreateIcon" className="icon-add" />
                  }
                  onClick={handleSendBotTelegram}
                >
                  Send telegram
                </Button>
              )} */}

              {/* <CloseReport open={open} handleClose={handleClose} /> */}
              <Button
                className="btn-create"
                startIcon={
                  <SiMicrosoftexcel alt="CreateIcon" className="icon-add" />
                }
                onClick={onDownload}
              >
                Export excel
              </Button>
              <Button
                className="btn-create"
                startIcon={<BsPrinter alt="CreateIcon" className="icon-add" />}
                onClick={handlePrint}
              >
                Print
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          display: reportType === "Income-Statement" ? "flex" : "none",
        }}
      >
        <Grid item container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Typography className="search-head-title">
              Select department
            </Typography>
            <SelectDepartment
              selectedVal={departmentVal}
              setSelectedVal={setDepartmentVal}
              className={"search-field"}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            sx={{
              display:
                reportType === "Income-Statement" &&
                departmentVal?.id === "64a52c65ad409eb75c87d8e1"
                  ? "block"
                  : "none",
            }}
          >
            <Typography className="search-head-title">Select form</Typography>
            <FormControl fullWidth size="small" className="search-field">
              <Select
                value={formIncomeStatement}
                onChange={(e) => setFormIncomeStatement(e.target.value)}
              >
                <MenuItem value="1">Form 1</MenuItem>
                <MenuItem value="2">Form 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* <Box
        sx={{
          marginTop: "20px",
          display: reportType === "BalanceSheet" ? "block" : "none",
        }}
      >
        <Grid item container spacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <Typography className="search-head-title">Select month year</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="MMM-yyyy"
                views={["year", "month"]}
                value={monthYear !== "" ? monthYear : ""}
                onChange={(e) => setMonthYear(e)}
                className="search-field"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box> */}

      <Box
        sx={{
          marginTop: "20px",
          // display: reportType === "BalanceSheet" ? "none" : "block",
        }}
      >
        <Grid item container spacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <Typography className="search-head-title">Select date</Typography>
            <FormControl fullWidth size="small" className="search-field">
              <Select
                value={dateSelect}
                onChange={(e) => setDateSelect(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="this_week">This week</MenuItem>
                <MenuItem value="this_month">This month</MenuItem>
                <MenuItem value="this_year">This year</MenuItem>
                <MenuItem value="last_week">Last week</MenuItem>
                <MenuItem value="last_month">Last month</MenuItem>
                <MenuItem value="last_year">Last year</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={4} lg={3}>
            <Typography className="search-head-title">
              Select from date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd-MM-yyyy"
                value={startDate !== "" ? startDate : ""}
                onChange={(e) => {
                  setDateSelect("");
                  setStartDate(e);
                }}
                className="search-field"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Typography className="search-head-title">
              Select to date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd-MM-yyyy"
                value={endDate !== "" ? endDate : ""}
                onChange={(e) => {
                  setEndDate(e);
                  setDateSelect("");
                }}
                className="search-field"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Box className="report-container-print" ref={componentRef}>
          {reportType === "BalanceSheet" ? (
            <div ref={tableRef} id="sendTelegram">
              <div className="report-top-title" style={{ fontWeight: "bold" }}>
                GO FOR GOLD
              </div>
              <div
                className="report-top-title"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                BALANCE SHEET {departmentVal?.title}
              </div>
              <div className="report-top-subtitle">
                {/* {monthYear ? (
                  <>
                    {moment(monthYear).daysInMonth() +
                      moment(monthYear).format(" MMM YYYY")}
                  </>
                ) : (
                  <>day -:- month -:- year - day -:- month -:- year</>
                )} */}
                {startDate || endDate ? (
                  <>
                    {moment(startDate).format("DD MMM YYYY")} To{" "}
                    {moment(endDate).format("DD MMM YYYY")}
                  </>
                ) : (
                  // <>day -:- month -:- year - day -:- month -:- year</>
                  <>All Date</>
                )}
              </div>
              <BalanceSheet
                startDate={startDate}
                endDate={endDate}
                // monthYear={monthYear}
              />
            </div>
          ) : null}

          {reportType === "Income-Statement" ? (
            <div ref={tableRef} id="sendTelegram">
              <div className="report-top-title" style={{ fontWeight: "bold" }}>
                GO FOR GOLD
              </div>
              <div
                className="report-top-title"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                INCOME STATEMENT {departmentVal?.title}
              </div>
              <div className="report-top-subtitle">
                {startDate || endDate ? (
                  <>
                    {moment(startDate).format("DD MMM YYYY")} To{" "}
                    {moment(endDate).format("DD MMM YYYY")}
                  </>
                ) : (
                  // <>day -:- month -:- year - day -:- month -:- year</>
                  <>All Date</>
                )}
              </div>
              <IncomeStatement
                startDate={startDate}
                endDate={endDate}
                departmentId={departmentVal?.id}
                formIncomeStatement={formIncomeStatement}
              />
            </div>
          ) : null}

          {reportType === "General-Ledger" ? (
            <div ref={tableRef} id="sendTelegram">
              <div className="report-top-title" style={{ fontWeight: "bold" }}>
                GO FOR GOLD
              </div>
              <div
                className="report-top-title"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                GENERAL LEDGER {departmentVal?.title}
              </div>
              <div className="report-top-subtitle">
                {startDate || endDate ? (
                  <>
                    {moment(startDate).format("DD MMM YYYY")} To{" "}
                    {moment(endDate).format("DD MMM YYYY")}
                  </>
                ) : (
                  // <>day -:- month -:- year - day -:- month -:- year</>
                  <>All Date</>
                )}
              </div>
              <GeneralLedger startDate={startDate} endDate={endDate} />
            </div>
          ) : null}
        </Box>
      </Box>
    </div>
  );
}
