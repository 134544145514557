import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
// layouts
// import Layout from "./Layout/LayoutOld";
import Layout from "./Layout/Layout";
//Page
import Dashboard from "./Pages/Dashboard";
import ChartOfAccount from "./Pages/ChartOfAccount";
import GeneralJournal from "./Pages/GeneralJournal";
import ViewGeneralJournal from "./Components/GeneralJournal/ViewGeneralJournal";
import Department from "./Pages/Department";
import ExpenseType from "./Pages/ExpenseType";
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import Setting from "./Pages/Setting";
import Report from "./Pages/Report";
import Page404 from "./Pages/Page404";
import { GET_USER_LOGIN } from "./Schema/User";
import { AuthContext } from "./Context/AuthContext";
import AccountingManagerment from "./Pages/AccountingManagerment";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {

  const { state, dispatch } = useContext(AuthContext);
  let navigate = useNavigate();

  // =================== get user login ==================
  const { data, refetch } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      // console.log("getUserLogin::", getUserLogin);
      if (getUserLogin?.user_first_name !== null) {
        window.localStorage.setItem("userLogin", JSON.stringify(getUserLogin));
      }  
    },
    fetchPolicy: "network-only",
  });


  useEffect(() => {
    async function handleRefetch() {
      await refetch();
    }
    if (data?.getUserLogin?.user_first_name === null) {
      handleRefetch();
    }
  }, [data?.getUserLogin]);


  // ====================== check state user login ==========================
  let departmentVal = JSON.parse(window.localStorage.getItem("departmentVal"));
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const [token, setToken] = useState( JSON.parse(window.localStorage.getItem("token")));

  React.useEffect(() => {
    async function checkUser() {
      let userToke = await JSON.parse(window.localStorage.getItem("token"));
      // console.log("userStorage",userStorage);
      if (userToke) {
        setToken(userToke);
        return;
      }

      if (state?.user?.token !== null && state?.user?.token !== undefined) {
        setToken(state?.user?.token);
        window.localStorage.setItem( "token", JSON.stringify(state?.user?.token) );
      } else {
        setToken(null);
      }
    }
    checkUser();
  }, [state?.user]);

  // End check Route ==========================================================================

  const LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    // { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "*", element: <Login /> },
  ]);

  const AccountingManagermentPage = useRoutes([
    { path: "/", element: <AccountingManagerment /> },
    { path: "/accounting-managerment", element: <AccountingManagerment /> },
    { path: "*", element: <AccountingManagerment /> },
  ]);

  const SuperAdminContent = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "chart-of-account", element: <ChartOfAccount /> },
        { path: "general-journal", element: <GeneralJournal /> },
        {
          path: "/general-journal/view-general-journal",
          element: <ViewGeneralJournal />,
        },
        { path: "user", element: <Users /> },
        { path: "report", element: <Report /> },
        { path: "setting", element: <Setting /> },
        { path: "/setting/department", element: <Department /> },
        { path: "/setting/users", element: <Users /> },
        { path: "/setting/expense-type", element: <ExpenseType /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  const AdminContent = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "chart-of-account", element: <ChartOfAccount /> },
        { path: "general-journal", element: <GeneralJournal /> },
        {
          path: "/general-journal/view-general-journal",
          element: <ViewGeneralJournal />,
        },
        { path: "user", element: <Users /> },
        { path: "report", element: <Report /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  const ReaderContent = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [  
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "report", element: <Report /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  // return AdminContent
  // console.log(token, "token");
  if (token !== null && token !== undefined && departmentVal?.id) {
      if (userLogin?.role === "Super Admin") {
        return SuperAdminContent;
      } else if (userLogin?.role === "Admin") {
        return AdminContent;
      } else {
        return ReaderContent;
      }
  } else if (token !== null && token !== undefined) {
      return AccountingManagermentPage;
  } else {
      return LoginPage;
  }  
}
