import React, { useState, useContext } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//Icons
import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import { BiHomeAlt } from "react-icons/bi";
import { RiFolderChartLine } from "react-icons/ri";
import { TbCopy } from "react-icons/tb";
//Srcs
import CompanyLogo from "../../Assets/goforgold.png";
import "./menunavbar.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open, handleDrawerClose }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  let location = useLocation();
  const navigate = useNavigate();

  const [userLogin, setUserLogin] = useState(
    JSON.parse(window.localStorage.getItem("userLogin"))
  );

  // console.log("userLogin::", userLogin);

  return (
    <Drawer variant="permanent" open={open} className="drawer-menu">
      <DrawerHeader sx={{ mt: 2 }}>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <IconButton
            onClick={() => navigate("/dashboard")}
            className="image-container"
          >
            <img src={CompanyLogo} alt="logo" className="logo" />
          </IconButton>
        </Stack>
      </DrawerHeader>

      {userLogin?.role === "Super Admin" || userLogin?.role === "Admin" || true ? (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => navigate("/")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <AiOutlineDashboard className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_dashboard")}
              </Typography>
            </ListItemButton>
          </ListItem>
          {/* ============================== Page chart of account ========================== */}
          <ListItem
            className={
              location.pathname === "/chart-of-account"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => navigate("/chart-of-account")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <BiHomeAlt className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_chart_of_account")}
              </Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== Page general journal ========================== */}
          <ListItem
            className={
              location.pathname === "/general-journal" ||
              location.pathname === "/general-journal/view-general-journal"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => navigate("/general-journal")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <TbCopy className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_general_journal")}
              </Typography>
            </ListItemButton>
          </ListItem>
          {/* ============================== Page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ? "list-item-active" : "list-item"
            }
            disablePadding
            onClick={() => navigate("/report")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <RiFolderChartLine className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_report")}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      ) : (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => navigate("/")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <AiOutlineDashboard className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_dashboard")}
              </Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== Page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ? "list-item-active" : "list-item"
            }
            disablePadding
            onClick={() => navigate("/report")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <RiFolderChartLine className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_report")}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      )}

      <Box sx={{ flexGrow: 1 }}></Box>

      {/* ============================== logout ========================== */}
      {userLogin?.role === "Super Admin" ? (
        <List className="list-menu">
          {/* =============================== Page Setting =============================== */}
          <ListItem
            className={
              location.pathname === "/setting" ||
              location.pathname === "/setting/department" ||
              location.pathname === "/setting/users" ||
              location.pathname === "/setting/expense-type"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => navigate("/setting")}
          >
            <ListItemButton className="list-item-button">
              <ListItemIcon className="list-item-icon">
                <AiOutlineSetting className="icon" />
              </ListItemIcon>
              <Typography className="list-item-text">
                {t("page_setting")}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      ) : null}
    </Drawer>
  );
}
