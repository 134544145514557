import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { CREATE_DEPATMENT } from "../../Schema/Depatment";

export default function CreateDepartment({ open, handleClose, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [createDepartment] = useMutation(CREATE_DEPATMENT, {
    onCompleted: ({ createDepartment }) => {


      
      setLoading(false);
      if (createDepartment.isSuccess) {
        setAlert(true, "success", createDepartment?.message);
        handleClose();
        resetForm();
        setRefetch();
      }else{
        setAlert(true, "error", createDepartment?.message);
      }
    },
    onError: (err) => {
      setLoading(false);
    },
  });
  //fromik
  const AddDepartment = Yup.object().shape({
    description: Yup.string(),
    department_name: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
      department_name: "",
    },

    validationSchema: AddDepartment,
    onSubmit: async (values) => {
      setLoading(true);
      createDepartment({
        variables: {
          input: {
            ...values,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {t("modal_title_add_department")}
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">
                  {t("thead_name")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("label_name")}
                  {...getFieldProps("department_name")}
                  error={Boolean(
                    touched.department_name && errors.department_name
                  )}
                  helperText={touched.department_name && errors.department_name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">
                  {t("thead_description")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder={t("labe_description")}
                  {...getFieldProps("description")}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
