import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import {
  CREATE_EXPENSE_TYPE,
  UPDATE_EXPENSE_TYPE,
} from "../../Schema/ExpenseType";

export default function CreateExpenseType({
  open,
  handleClose,
  setRefetch,
  editData,
  dialogTitle,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [createExpenseType] = useMutation(CREATE_EXPENSE_TYPE, {
    onCompleted: ({ createExpenseType }) => {
      setLoading(false);
      if (createExpenseType.isSuccess) {
        setAlert(true, "success", createExpenseType?.message);
        handleClose();
        resetForm();
        setRefetch();
      }else{
        setAlert(true, "error", createExpenseType?.message);
      }
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  const [updateExpenseType] = useMutation(UPDATE_EXPENSE_TYPE, {
    onCompleted: ({ updateExpenseType }) => {
      setLoading(false);
      if (updateExpenseType.isSuccess) {
        setAlert(true, "success", updateExpenseType?.message);
        handleClose();
        resetForm();
        setRefetch();
      }
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  //fromik
  const AddDepartment = Yup.object().shape({
    expense_name: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      expense_name: "",
    },

    validationSchema: AddDepartment,
    onSubmit: async (values) => {
      setLoading(true);
      if (dialogTitle === "Create") {
        createExpenseType({
          variables: {
            input: {
              ...values,
            },
          },
        });
      } else {
        updateExpenseType({
          variables: {
            expenseTypeId: editData?._id,
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setFieldValue("expense_name", editData?.expense_name);
    }
  }, [editData]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Expense Type
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">Expense type</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="expense type"
                  {...getFieldProps("expense_name")}
                  error={Boolean(touched.expense_name && errors.expense_name)}
                  helperText={touched.expense_name && errors.expense_name}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {dialogTitle}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
