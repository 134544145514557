import React, { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./alertmessage.scss";

import { AuthContext } from "../../Context/AuthContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage() {
  const { alert, setAlert } = useContext(AuthContext);

  let open = alert()?.open;
  let message = alert()?.message;
  let status = alert()?.status;

  const handleCloseAlert = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false, "", "");
  };

  // severity="success"
  // severity="error"

  return (
    <div className="alert-message">
      {status === "success" ? (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className="snackbar-alert"
        >
          <Alert
            onClose={handleCloseAlert}
            className={"alert-success"}
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : null}

      {status === "error" ? (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className="snackbar-alert"
        >
          <Alert
            onClose={handleCloseAlert}
            className={"alert-error"}
            severity="error"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
}
