import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Avatar,
  IconButton,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
//Icons
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import CircularProgress from "@mui/material/CircularProgress";
//Srcs
import "./login.scss";
import { AuthContext } from "../Context/AuthContext";
import CompanyImage from "../Assets/goforgold.png";
import Auth from "../Auth";
import { LOGIN_SHCEMA } from "../Schema/Login";

export default function Login() {
  const navigate = useNavigate();
  const { setAlert, dispatch } = useContext(AuthContext);
  const [iconReload, setIconReload] = useState(false);

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [login] = useMutation(LOGIN_SHCEMA, {
    onCompleted: ({ login }) => {
      setIconReload(false);
      console.log("login::", login);
      if (login?.is_success === true) {
        setAlert(true, "success", login?.message);
        // handleClose();
        setTimeout(() => {
          navigate("/accounting-managerment");
          // setIconReload(false);
          window.localStorage.setItem("token", JSON.stringify(login?.token));
          window.localStorage.setItem("userLogin", JSON.stringify(login?.data));
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              email: login?.data?.user_email
                ? login?.data?.user_email
                : "customtoken@user.com",
              token: login?.token,
            },
          });
        }, 1200);
      } else {
        setAlert(true, "error", login?.message);
      }
    },
    onError: (error) => {
      setIconReload(false);
      setAlert(true, "error", error?.message);
    },
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email!").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must be 8 characters!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      // console.log(first);
      setIconReload(true);
      
      login({
        variables: {
          email: values?.email,
          password: values?.password,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box className="login-page">
          <Box className="background-image" />
          <Box className="container">
            <Stack direction="row" justifyContent="center">
              <IconButton className="logo-container">
                <Avatar
                  sx={{ width: "140px", height: "140px" }}
                  variant="square"
                  alt="logo"
                  src={CompanyImage}
                />
              </IconButton>
            </Stack>
            <Box className="box-text" sx={{ mt: 4 }}>
              <Stack
                direction="column"
                justifyContent="center"
                spacing={1}
                className="title-login"
              >
                <Typography className="title">
                  Welcome to GO FOR GOLD ACCOUNTING
                </Typography>
                <Typography className="sub-title" align="center">
                  Sign In to continue
                </Typography>
              </Stack>
            </Box>

            <Box className="box-login" sx={{ mt: 3 }}>
              <Stack direction="column" justifyContent="center" spacing={2}>
                <TextField
                  className="text-field"
                  size="small"
                  placeholder="example@company.com"
                  fullWidth
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon className="icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className="text-field"
                  type={show ? "text" : "password"}
                  size="small"
                  placeholder="password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpsOutlinedIcon className="icon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon sx={{ cursor: "pointer" }} />
                        ) : (
                          <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Link
                  to="/forgotpassword"
                  style={{ textDecorationColor: "#fff" }}
                >
                  <Typography
                    variant="subtitle2"
                    align="right"
                    color="#fff"
                    fontWeight="bold"
                  >
                    Forgot password?
                  </Typography>
                </Link> */}

                <Button
                  className="btb-sign-in"
                  type="submit"
                  endIcon={
                    iconReload ? (
                      <CircularProgress sx={{ color: "#027816" }} size="20px" />
                    ) : null
                  }
                >
                  Sign In
                </Button>
              </Stack>
            </Box>
          </Box>
          <Typography
            variant="body2"
            align="center"
            color="#fff"
            sx={{ mb: 3, letterSpacing: "2px" }}
          >
            @Copyright 2022, GO FOR GOLD
          </Typography>
        </Box>
      </Form>
    </FormikProvider>
  );
}
