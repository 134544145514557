import React, { useState, useEffect, useContext } from "react";
import {
  Stack,
  Box,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
//Date
import moment from "moment";
//Srcs
import "./dashboard.scss";
import { AuthContext } from "../Context/AuthContext";
import { get_date_filter } from "../Function/DateFilter";
import TotalCase from "../Components/Dashboard/TotalCase";
import { translateLauguage } from "../Function/Translate";
import BlockHeader from "../Components/Dashboard/BlockHeader";
import AccountChart from "../Components/Dashboard/AccountChart";
import ExpenseTable from "../Components/Dashboard/ExpenseTable";
import RevenueChart from "../Components/Dashboard/RevenueChart";
import CoustOfSaleChart from "../Components/Dashboard/CoustOfSaleChart";

export default function Dashboard() {
  // ================= Change Language ==================
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [dateSelect, setDateSelect] = useState("this_month");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const [departmentVal, setDepartmentVal] = useState(
    JSON.parse(window.localStorage.getItem("departmentVal"))
  );

  // ============================ Filter date function ============================
  useEffect(() => {
    async function getDateFiler() {
      if (dateSelect !== "all" && dateSelect !== "") {
        let date = await get_date_filter(dateSelect);
        setStartDate(date?.fromDate);
        setEndDate(date?.toDate);
      } else {
        setStartDate(moment());
        setEndDate(moment());
      }
    }
    getDateFiler();
  }, [dateSelect]);

  // console.log("departmentVal::", departmentVal);

  return (
    <div className="dashboard-page">
      <Grid item container spacing={3}>
        <Grid item xs={6} sx={6} md={5} lg={4} xl={3}>
          <Stack direction="row" spacing={2} className="page-header">
            <Stack direction="column" justifyContent="center">
              <Typography className="page-title">
                {t("page_dashboard")}
              </Typography>
              <Typography className="page-subtitle">
                Have a nice day and great work!
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Box sx={{ flexGrow: 1 }} />
        <Grid item xs={6} sx={6} md={5} lg={4} xl={3}>
          <Stack direction="row" spacing={1}>
            <Stack direction="column" justifyContent="center">
              <Typography className="search-title">Select date :</Typography>
            </Stack>
            <FormControl fullWidth size="small" className="search-field">
              <Select
                value={dateSelect}
                onChange={(e) => setDateSelect(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="this_week">This week</MenuItem>
                <MenuItem value="this_month">This month</MenuItem>
                <MenuItem value="this_year">This year</MenuItem>
                <MenuItem value="last_week">Last week</MenuItem>
                <MenuItem value="last_month">Last month</MenuItem>
                <MenuItem value="last_year">Last year</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      {departmentVal?.id === "64a52c65ad409eb75c87d8e1" ? (
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BlockHeader
                startDate={startDate}
                endDate={endDate}
                departmentId={departmentVal?.id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <AccountChart startDate={startDate} endDate={endDate} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TotalCase
                startDate={startDate}
                endDate={endDate}
                departmentId={departmentVal?.id}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BlockHeader
                startDate={startDate}
                endDate={endDate}
                departmentId={departmentVal?.id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7.2} xl={7.2}>
              <Stack direction="column" spacing={2}>
                <RevenueChart
                  startDate={startDate}
                  endDate={endDate}
                  departmentId={departmentVal?.id}
                />
                <CoustOfSaleChart
                  startDate={startDate}
                  endDate={endDate}
                  departmentId={departmentVal?.id}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4.8} xl={4.8}>
              <ExpenseTable
                startDate={startDate}
                endDate={endDate}
                departmentId={departmentVal?.id}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
